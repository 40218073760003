<template>
  <div>
    <memlist-card class="w-50 mx-auto mt-16">

      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #00b315;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h4 class="event-name" style="text-align: center;">Faktura skapad!</h4>
          <br />
          <p>
            Din faktura har skapats och kommer skickas till din e-post.
          </p>
        </div>
      </div>

    </memlist-card>
  
  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'TestInvoiceDonePage',
  components: {
    
  },
  async mounted() {
    const shop_order_id = this.$route.params.shop_order_id;
    const token = this.$route.params.token;

    await this.get_order(token, shop_order_id);
  },
  mixins: [ toasts ],
  data() {
    return {
      order: null
    };
  },
  methods: {
    async get_order(token, shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/token/${token}/${shop_order_id}`);

        if (res.status === 200) {

          if (res.data.error) {
            this.error = res.data.error;
          }

          this.order = res.data;
        }
      }
      catch (err) {
        console.error('get_order error', err);
      }

      throw `unable to get order`;
    },
  }
};
</script>


<style lang="css" scoped>


</style>